body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body, #root {
  height: 100%;
}


p, ul, ol, h1, h2, h3, h4 {
  margin: 0;
}
ul, ol {
  list-style: none;
}
button {
  all: unset;
}

:root {
  --explorer-300: hsla(200, 93%, 72%, 1);
  --captain: hsla(46, 100%, 62%, 1);
  
  --neutral-20: hsla(200, 100%, 98%, 1);
  --neutral-100: hsla(200, 49%, 92%, 1);
  --neutral-150: hsla(200, 33%, 84%, 1);
  --neutral-250: hsla(200, 25%, 75%, 1);
  --neutral-700: hsla(200, 18%, 30%, 1);
  
  --gray-600: hsla(0, 0%, 40%, 1);
  
  
  
  --paytone-one-70: 70px/0.685 "Paytone One", "Trebuchet MS", sans-serif;
  --cabin-38: 500 38px 'Cabin', sans-serif;
  --cabin-34: 400 34px 'Cabin', sans-serif;
  --cabin-24: 400 24px 'Cabin', sans-serif;
  --cabin-20: 400 20px 'Cabin', sans-serif;
  --cabin-15: 400 15px 'Cabin', sans-serif;
}
