.app {
  height: 100%;
  
  display: flex;
  flex-direction: column;
}

.header {
  z-index: 2;
  /* react-three-fiber canvas can be greedy, so don't shrink */
  flex-shrink: 0;
}


.mainContent {
  flex-grow: 1;
  
  display: flex;
}

.sidebar {
  width: 20vw;
  /* react-three-fiber canvas can be greedy, so don't shrink */
  flex-shrink: 0;
  z-index: 1;
}

.canvasPanel {
  flex-grow: 1;
  /* don't resize to child canvas, so we can measure this div's size */
  overflow: hidden;
  background: var(--neutral-20);
  
  position: relative;
}

.rightSideBar {
  position: absolute;
  top: 0;
  right: 0;
}



