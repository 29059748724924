

.sidebar {
  /* height: 87vh; */
  background-color: white;
  
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 25px;
  box-sizing: border-box;
  
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.25);
  /* z-index: 1!important; */
}


.section {
  font: var(--cabin-24);
}

.subsection {
  font: var(--cabin-20);
}

.topicButton {
  cursor: pointer;
}
