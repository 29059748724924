



.page {
  height: 100%;
  
  display: flex;
  flex-direction: column;
  
  background: var(--neutral-20);
}


.header {
  flex-shrink: 1;
  
  font: var(--cabin-20);
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 25% 10px;
  
  text-align: center;
}

.main {
  flex-grow: 1;
  
  display: flex;
  flex-direction: row;
}

.userView {
  flex: 1;
  
  display: flex;
  flex-direction: column;
}

.userView h2 {
  font: var(--cabin-38);
  text-align: center;
}


/* decrease ui scale (fit more px in the same screen size) */
/* https://stackoverflow.com/questions/166160/how-can-i-scale-the-content-of-an-iframe */
.userView .app {
  flex: 1;
  overflow: hidden;
}
.userView .app iframe {
  --scale: 0.9;
  transform: scale(var(--scale));
  transform-origin: top left;
  height: calc(100% / var(--scale));
  width: calc(100% / var(--scale));
  
  background: white;
}







